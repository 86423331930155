.custom-checkbox {
    .custom-control-input ~ .custom-control-label {
        cursor: pointer;
        font-size: $font-size-sm;
    }

    .custom-control-input {
        &:checked {
            ~ .custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-checked-border-color;
                }
                &::after {
                    background-image: $custom-checkbox-indicator-icon-checked;
                }
            }
        }

        &:disabled {
            ~ .custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-disabled-bg;
                }
            }

            &:checked {
                &::before {
                    border-color: $custom-control-indicator-checked-disabled-bg;
                }
            }
        }
    }
}

.custom-checkbox {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  
    .custom-checkbox-input {
      width: 24px;
      height: 24px;
      appearance: none;
      background-color: #fff;
      border: 1px solid #5e72e43b;
      border-radius: 5px;
      box-shadow: 0 0 5px rgba(94, 114, 228, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-bottom: 3px;
  
      &:checked {
        background-color: #5e72e4;
        border-color: #5e72e4;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGxpbmUgeDE9IjEiIHkxPSI0IiB4Mj0iNCIgeTI9IjciIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+PGxpbmUgeDE9IjQiIHkxPSI3IiB4Mj0iMTEiIHkyPSIxIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPjwvc3ZnPg==');
        background-repeat: no-repeat;
        background-position: center;
      }
  
      &:not(:checked) {
        background-color: #fff;
        border-color: rgba(94, 114, 228, 0.5);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        background-image: none;
      }
    }
  
    .custom-checkbox-label {
      height: 25px;
      font-size: 16px;
      margin-left: 20px;
      font-weight: 600; 
      cursor: pointer;
    }
  }
  