button:focus, :focus {
  outline: none;
}

@font-face {
  font-family: Cooper-Std;
  src: url('../../fonts/Cooper\ Std\ Black.ttf');
}

.permanent-font {
  font-family: "Permanent Marker" !important;
  color: #212529;
  font-weight: 500;
  font-size: 40px;
}

.cooper-font {
	font-family: Cooper-Std;
  color: #212529;
  font-size: 20 px;
}

.login-font {
  z-index: 1;
  color: #212529;
}

.login-border {
  width: 100vw;
  height: 2px;
  background-color: #212529;
}


.secondary-border {
  border: 1px solid rgba(100, 100, 100, 0.35) !important;
} 
  
.secondary-bg {
  border-right: 1.5px solid rgba(100, 100, 100, 0.35) !important;
  background-color: rgba(100, 100, 100, 0.1) !important;
}
  
.secondary-color {
  color: gray !important;
}